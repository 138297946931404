import React from "react";
import { motion } from "framer-motion";
import AImage from "../images/hero1.png";
import WholeDried from "../images/whole-dried.jpg";
import OrganicGinger from "../images/organic-ginger.jpg";
import GingerOil from "../images/ginger-oil.png";
import GroundGingerPowder from "../images/ground-ginger-powder.jpg";
import Zobo from "../images/Hibiscus sabdariffa.jpg";
import PageHeader from "../components/PageHeader";
import Reels from "../videos/janiba-ginger-reels.mp4";

const productsList = [
  {
    id: 1,
    title: "Whole Dried Ginger",
    content:
      "Hand-picked and sun-dried to perfection, our whole dried ginger offers an intense, aromatic flavor that is perfect for culinary applications.",
    image: WholeDried,
  },
  {
    id: 2,
    title: "Ground Ginger Powder",
    content:
      "Our ginger powder is processed from the finest ginger roots, ensuring a potent, ground spice that enhances the flavors of various dishes.",
    image: GroundGingerPowder,
  },
  {
    id: 3,
    title: "Organic Ginger",
    content:
      "We take pride in our commitment to sustainable agriculture and offer certified organic dried ginger products complying with international organic standards.",
    image: OrganicGinger,
  },
  {
    id: 4,
    title: "Specialty Ginger Products",
    content:
      "We also offer ginger extracts, ginger oil, and ginger-infused products for various industries, such as pharmaceuticals, cosmetics, and food processing.",
    image: GingerOil,
  },
  {
    id: 5,
    title: "Hibiscus sabdariffa (Zobo)",
    content:
      "We cultivate and process high-quality Hibiscus sabdariffa, known for its vibrant color and tart flavor. This versatile flower is used in a variety of products, including teas, beverages, jams, and health supplements.",
    image: Zobo,
  },
];

function About() {
  return (
    <>
      <div className="p-12 md:p-6">
        <div className="shadow-lg">
          <div className="flex lg:flex-col bg-gray p-6 sm:p-2">
            <div className="A-page flex-1 lg:order-1 order-2 flex items-center justify-center rounded-lg w-full overflow-hidden">
              {/* <img
                src={AImage}
                alt="Aboutimage"
                className="p-4 h-full rounded-md hover:scale-150 transition-all duration-300 ease-in-out"
              /> */}
              <div className="relative h-[600px] w-full overflow-hidden bg-maincolor text-purewhite">
                <video
                  autoPlay
                  loop
                  muted
                  className="absolute top-0 left-0 w-full h-full object-cover"
                >
                  <source src={Reels} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="h-full flex-1 md:order-2 order-1 text-lg  p-8 rounded-tr-lg rounded-br-lg">
              <PageHeader title="About Us" />
              <div className="text-[18px] md:text-[16px] space-y-6 overflow-hidden">
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                >
                  <strong>Janiba Ginger Limited</strong> (RC No: 2020935) is an
                  export-oriented company dedicated to providing premium dried
                  sliced ginger, Hibiscus sabdariffa, and a healthy line of
                  ginger spice fruit juices from Nigeria to discerning markets
                  in Europe and the Americas. Locally, our products are
                  celebrated for their unique qualities and versatile uses,
                  while internationally, they cater to the growing demand for
                  exotic and healthy food products.{" "}
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                >
                  Our organic and non-organically farmed ginger offers a unique
                  flavour and aroma that sets it apart from competitors,
                  emphasizing the authentic taste of Nigerian ginger.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                >
                  We are confident in our ability to address challenges through
                  quality control, regulatory compliance, efficient logistics,
                  and strategic partnerships.{" "}
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: 200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                >
                  We have established a strong presence and our products are
                  favored by gourmet chefs, food manufacturers, pharmaceutical
                  companies, cosmetic brands, and health-conscious consumers. We
                  offer tailored solutions to meet the unique requirements of
                  these diverse markets.
                </motion.p>
              </div>
            </div>
          </div>
          <div className="py-12 px-32 md:px-16"></div>
          <div className="px-32 xl:px-24 lg:px-16 md:px-12 sm:px-8 p-6 sm:p-2  -z-30">
            <h1 className="text-center text-3xl text-secondarycolor font-bold uppercase underline underline-offset-2">
              Our Product Includes:
            </h1>
            <div className="grid w-full grid-cols-8 gap-16 md:gap-8 p-6">
              {productsList.map((items) => (
                <motion.div
                  key={items.id}
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="relative col-span-4 lg:col-span-8 bg-maincolor text-maincolor border-maincolor border border-solid p-4 rounded-md"
                >
                  <div className="absolute top-2 -right-3 -z-10 w-[102%] h-[103%] bg-gradient-to-r from-black to-gray rounded-md " />
                  <h2 className="text-2xl font-bold mb-2 text-center text-purewhite">
                    {items.title}
                  </h2>
                  <div className="flex sm:flex-col gap-3 sm:gap-6 sm:items-center">
                    <img
                      src={items.image}
                      alt="whole-dried"
                      className="w-24 border-r-2 border-b-2 border-solid border-black p-3 rounded-full"
                    />
                    <p className="text-md sm:text-sm font-light text-gray">
                      {items.content}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
