import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaMobileAlt,
  FaEnvelopeOpen,
  FaLinkedin,
  FaArrowUp,
  FaMapMarker,
  FaWhatsapp,
} from "react-icons/fa";
import Logo from "../../images/janiba-logo-png.png";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer className=" bg-black text-whitesmokecolor p-16 md:p-12">
      <div className="flex justify-end">
        <FaArrowUp
          className="text-3xl text-secondarycolor cursor-pointer mb-4"
          onClick={scrollToTop}
        />
      </div>
      <div className="flex md:flex-col gap-6">
        <div className="flex-1 flex items-center justify-center">
          <img src={Logo} alt="logo" className="w-48" />
        </div>
        <div className="flex-1">
          <h2 className="text-lg font-bold uppercase underline underline-offset-4 text-secondarycolor">
            About us
          </h2>
          <p className="md:text-sm">
            Janiba Ginger Limited (RC No: 2020935) is an export-oriented company
            dedicated to providing premium dried sliced ginger, Hibiscus
            sabdariffa, and a healthy line of ginger spice fruit juices from
            Nigeria to discerning markets in Europe and the Americas. Locally,
            our products are celebrated for their unique qualities and versatile
            uses, while internationally, they cater to the growing demand for
            exotic and healthy food products.....
          </p>
          <div className="mt-24 md:mt-8 text-center">
            <p className="mb-2 text-secondarycolor">Connect with us:</p>
            <div className="flex items-center justify-center gap-6 text-2xl">
              <Link to={"https://twitter.com/JanibaGinger"}>
                <FaTwitter className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
              <Link to={"https://wa.me/08187050789"}>
                <FaWhatsapp className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
              <Link to={"https://instagram.com"}>
                <FaInstagram className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-y-6 md:text-center md:items-center">
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-secondarycolor">
              Location:
            </h2>
            <div>
              <div>
                <h2 className="font-bold uppercase">Kaduna</h2>
                <p className="flex gap-1 items-center">
                  <FaMapMarker className="text-maincolor" /> CB Finance House,
                  Kaduna,
                </p>
                <p> 16E Ahmadu Bello Way,</p>
                <p>Kaduna State.</p>
              </div>
              {/* <div>
                <h2 className="font-bold uppercase">Abuja</h2>
                <p className="flex gap-1 items-center">
                  <FaMapMarker className="text-maincolor" />
                  No. 35, Gana Street,
                </p>
                <p>Maitama. Abuja.</p>
              </div> */}
            </div>
          </div>
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-secondarycolor">
              Email:
            </h2>
            <p className="flex gap-1 items-center">
              <FaEnvelopeOpen className="text-maincolor" />:
              info@janibaginger.com
            </p>
          </div>
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-secondarycolor">
              Phone:
            </h2>
            <p className="flex gap-1 items-center">
              <FaMobileAlt className="text-maincolor" />: +234 (818) 705 0789,
              +234 (908) 303 8664
            </p>
          </div>
        </div>
      </div>
      <div className="text-sm lg:mt-4 lg:text-center md:text-xs">
        &copy; {new Date().getFullYear()} Janiba Ginger Limited All Right
        Reserved
      </div>
    </footer>
  );
}

export default Footer;
