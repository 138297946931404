import React from "react";
import { Link, useNavigate } from "react-router-dom";
import BGVideos from "../videos/clipped-janiba-bmo.mp4"; // Update with the correct path to your video

function Showcase() {
  return (
    <div className="relative h-screen w-full overflow-hidden bg-maincolor text-purewhite">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src={BGVideos} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay Content */}
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-center text-white bg-black/50 p-4">
        <h1 className="text-4xl md:text-6xl sm:text-3xl font-bold mb-4">
          Janiba Ginger Ltd.
        </h1>
        <p className="text-lg sm:text-sm md:text-xl mb-6 max-w-3xl leading-relaxed">
          Janiba Ginger Ltd: Exporting Nigeria's Finest Ginger & Hibiscus,
          Delivering Health and Quality ginger spice fruit juices Worldwide.
        </p>
        <Link
          to={"/products"}
          className="px-6 py-3 bg-maincolor text-white font-bold rounded-md hover:bg-seccolor transition duration-300"
        >
          Our Products
        </Link>
      </div>
    </div>
  );
}

export default Showcase;
