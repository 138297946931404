import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick1 from "../images/slides/celery.jpg";
import Slick2 from "../images/slides/beetroot.jpg";
import Slick3 from "../images/slides/carrot.jpg";
import Slick4 from "../images/slides/jolly.jpg";
import Slick5 from "../images/slides/watermelon.jpg";

function ProductSlides() {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    arrows: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="slick-slider">
      <div className="slick-image">
        <img src={Slick1} alt="slideImage" className="w-full rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick2} alt="slideImage" className="w-full rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick3} alt="slideImage" className="w-full rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick4} alt="slideImage" className="w-full rounded-md" />
      </div>
      <div className="slick-image">
        <img src={Slick5} alt="slideImage" className="w-full rounded-md" />
      </div>
    </Slider>
  );
}

export default ProductSlides;
