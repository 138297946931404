import React from "react";
import { motion } from "framer-motion";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Link } from "react-router-dom";

const clearing = [
  {
    original: "https://i.ibb.co/4TbrnNS/hero1.png",
    description: "Our super heallthy amazing products",
  },
  {
    original: "https://i.ibb.co/rKPcDBh/hero2.png",
    description: "Pineapple Julce",
  },
  {
    original: "https://i.ibb.co/Z1KsHRf/hero3.png",
    description: "Jolly Banana Juice",
  },
  {
    original: "https://i.ibb.co/XtX9k33/hero4.png",
    description: "Watermelon Juice",
  },
  {
    original: "https://i.ibb.co/YpvGYWW/hero5.png",
    description: "Cupcakes and Cookies",
  },
  {
    original: "https://i.ibb.co/DQhRqJP/hero6.png",
    description: "Brownies",
  },
  {
    original: "https://i.ibb.co/SBdvSJh/hero7.png",
    description: "Tumeric Powder",
  },
  {
    original: "https://i.ibb.co/gjCwstm/hero8.png",
    description: "Ginger Garlic Powder",
  },
  {
    original: "https://i.ibb.co/zGnpb64/hero9.png",
    description: "Ginger Powder",
  },
  // {
  //   original: "https://i.ibb.co/D9xLQ2q/celery-main.png",
  //   description: "Celery Drink",
  // },
  // {
  //   original: "https://i.ibb.co/85qByz8/beetroot-nain.png",
  //   description: "Beetroot Rouge",
  // },
];

const quote = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
};

const singleWord = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

const smallText = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { duration: 1 } },
};

const buttonAni = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { delay: 1.5 } },
};

function Hero() {
  const heroText = "at Janiba Ginger Limited..";
  return (
    <div className=" px-32 py-8 xl:px-24 lg:px-16 md:px-12 sm:px-8">
      <div className="flex md:flex-col items-center justify-center">
        <div className="flex-1 md:order-2 space-y-6">
          <motion.h1
            className="text-6xl lg:text-5xl md:text-4xl md:mt-6 capitalize font-bold mb-3 text-secondarycolor"
            variants={quote}
            initial="initial"
            animate="animate"
          >
            {heroText.split(" ").map((word, index) => (
              <motion.span
                key={word + "-" + index}
                className="inline-block"
                variants={singleWord}
                initial="initial"
                animate="animate"
              >
                {word}&nbsp;
              </motion.span>
            ))}
          </motion.h1>
          <motion.p
            variants={smallText}
            initial="initial"
            animate="animate"
            className="text-lg md:text-sm text-gray-600 capitalize"
          >
            {" "}
            We Specialize in the cultivation, processing, and export of
            high-quality dried ginger, Hibiscus sabdariffa, and a healthy line
            of ginger spiced fruit juices. Committed to quality, sustainability,
            and ethical business practices.
          </motion.p>
          <div>
            <Link href={"/about"}>
              <motion.button
                variants={buttonAni}
                initial="initial"
                animate="animate"
                className="bg-gradient-to-r from-maincolor to-black text-whitesmokecolor hover:bg-gradient-to-l uppercase py-4 px-12 md:py-3 md:px-6 rounded-md transition-all duration-300 ease-in-out hover:bg-white hover:text-gray-700"
              >
                Know More
              </motion.button>
            </Link>
          </div>
        </div>
        <div className=" flex-1 flex items-center justify-center m-3">
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="relative p-4 rounded-2xl"
          >
            <div className="absolute -top-1 -left-1 -z-10 w-[30%] h-[30%] md:w-[20%] md:h-[20%] bg-maincolor rounded-lg" />
            <div className="absolute -bottom-1 -right-1 -z-10 w-[30%] h-[30%] md:w-[20%] md:h-[20%] bg-secondarycolor  rounded-lg" />
            <div className="bg-gradient-to-r from-darkgray to-maincolor rounded-lg object-contain">
              <ImageGallery
                items={clearing}
                showNav={false}
                showPlayButton={false}
                autoPlay={true}
                slideInterval={2000}
                showFullscreenButton={false}
              />
            </div>
          </motion.div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default Hero;
