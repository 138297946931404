import React, { useEffect, useState } from "react";
import Hero from "../components/hero/Hero";
import About from "../components/home/About";
import WhyChooseUS from "../components/home/WhyChooseUS";
import ModalImage from "../images/janiba-images/weekends.jpeg";
import Showcase from "../components/Showcase";

function Home() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const lastShownDate = localStorage.getItem("lastShownDate");
    const today = new Date();
    const isFriday = today.getDay() === 4; // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

    if (!lastShownDate && isFriday) {
      // If the modal has never been shown and today is Friday, show the modal
      setShowModal(true);
      localStorage.setItem("lastShownDate", today.toISOString());
    } else if (lastShownDate) {
      // If the modal has been shown before, check if 24 hours have passed
      const lastShownDateTime = new Date(lastShownDate).getTime();
      const twentyFourHoursAgo = today.getTime() - 24 * 60 * 60 * 1000;

      if (isFriday && lastShownDateTime < twentyFourHoursAgo) {
        // If it's Friday and 24 hours have passed since the last shown, show the modal
        setShowModal(true);
        localStorage.setItem("lastShownDate", today.toISOString());
      }
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showModal && (
        <div className="fixed z-30 top-0 left-0 w-full h-full p-24 sm:p-8 bg-black bg-opacity-90 flex items-center justify-center">
          <div className="bg-white rounded-md shadow-md  flex items-center justify-center">
            <span
              className="absolute top-0 right-0 p-3 cursor-pointer bg-maincolor text-purewhite text-4xl font-bold"
              onClick={closeModal}
            >
              &times;
            </span>
            <div className="w-[50%] sm:w-[90%]">
              <img src={ModalImage} alt="modal" />
            </div>
          </div>
        </div>
      )}
      <>
        <Hero />
        <About />
        <Showcase />
        <WhyChooseUS />
      </>
    </div>
  );
}

export default Home;
